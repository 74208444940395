import type { Stripe, StripeElements } from '@stripe/stripe-js'

import type { BusinessBilling, DefaultPaymentMethod } from '@/repository/legacy.ts'

interface StripeBillingDetails {
  name: string
  address?: import('@stripe/stripe-js').PaymentMethodCreateParams.BillingDetails.Address
  email?: string
  phone?: string
}

interface LegacyBusinessState {
  stripeInstance: Stripe | null
  defaultCode: string
  billing?: Partial<BusinessBilling>
  defaultPaymentMethod: DefaultPaymentMethod | null
}

export const useLegacyBusinessStore = defineStore('legacyBusiness', {
  state: (): LegacyBusinessState => ({
    stripeInstance: null,
    defaultCode: 'eur',
    billing: {},
    defaultPaymentMethod: null,
  }),

  getters: {
    businessCurrency(): string {
      return this.billing?.next_invoice?.currency ?? this.defaultCode
    },
  },

  actions: {
    async getBilling(businessId: string) {
      const { legacyRepository } = useApi()

      try {
        /**
         * This won't have a value if the customer doesn't
         * pay via stripe (e.g. old enterprise customers)
         */
        const res = await legacyRepository.getBusinessInfo(businessId)
        this.$patch({ billing: res })
      } catch {
        throw new Error('Failed to get billing information')
      }
    },
    async cancelBizSubscription(businessId: string) {
      const { legacyRepository } = useApi()
      await legacyRepository.cancelBusinessSubscription(businessId)
    },
    async reactivateBizSubscription(businessId: string) {
      const { legacyRepository } = useApi()
      await legacyRepository.reactivateBusinessSubscription(businessId)
    },
    async getStripePaymentMethods() {
      const businessStore = useBusinessStore()
      const { legacyRepository } = useApi()

      const defaultPaymentMethod = await legacyRepository.getPaymentMethods(businessStore.id)
      this.$patch({ defaultPaymentMethod })
    },
    async setupStripe(): Promise<void> {
      const { $config } = useNuxtApp()
      if (this.stripeInstance) return
      try {
        const stripe = await import(/* webpackChunkName: "stripe-loader" */ '@stripe/stripe-js')
        this.stripeInstance = await stripe.loadStripe($config.public.stripePublishableKey || '')
      } catch (error) {
        const { logError } = useErrorHandling()
        logError('stripe initialization error', error)
      }
    },
    async getAndConfirmStripeSetupIntent({
      billingDetails,
      stripeElements,
      country,
    }: {
      billingDetails: StripeBillingDetails
      stripeElements: StripeElements
      country: string
    }) {
      await this.setupStripe()
      if (!this.stripeInstance) throw new StripePaymentError('Stripe not found', 'general')

      const { legacyRepository } = useApi()

      const returnUrl = window.location.href

      const response = (await this.stripeInstance.confirmSetup({
        // `Elements`
        elements: stripeElements,
        confirmParams: {
          return_url: returnUrl,
          payment_method_data: {
            billing_details: billingDetails,
          },
        },
        redirect: 'if_required',
      })) as undefined | { setupIntent: { id: string } } | { error: { message: string; code: string } }

      if (response && 'error' in response) {
        throw new StripePaymentError(response.error.message ?? 'General Stripe error', response.error.code)
      }

      const businessStore = useBusinessStore()
      const id = response?.setupIntent?.id

      if (id) await legacyRepository.confirmSetupIntent(businessStore.id, id, country)
    },
  },
})
